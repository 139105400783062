import { useEffect } from 'react';

import { UserProfileResponse } from '@/api/types';
import setFirstPartyCookie from '@/lib/set-first-party-cookie';

const useFirstPartyCookie = (
  userProfile: UserProfileResponse | null,
  pagePath?: string,
) => {
  const userProfileString = userProfile && JSON.stringify(userProfile);

  useEffect(() => {
    const getCookie = async () => {
      if (userProfileString) {
        await setFirstPartyCookie();
      }
    };
    getCookie();
  }, [userProfileString, pagePath]);
};

export default useFirstPartyCookie;
