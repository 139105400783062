/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
const generateFractionsObject = require('./utils/generate-fractions-object');

const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: {
    DEFAULT: '#121113',
    light: '#2B2A2D',
    extra: '#000000',
  },
  white: '#ffffff',
  gray: {
    1: '#403B45',
    2: '#828282',
    3: '#BDBDBD',
    4: '#E0E0E0',
  },
  red: {
    DEFAULT: '#ED1C24',
    medium: '#EB5757',
    light: '#FAF3F8',
    'light-darker': '#F0DEE4',
  },
  pink: {
    DEFAULT: '#EFAAE8',
    lighter: '#E8D6E9',
    gray: '#756C75'
  },
  lilac: '#AA57EB',
  green: '#49BB84',
  air: {
    black: '#000000',
    deepBlue: '#0f2355',
    mediumBlue: '#03427B',
    lila: {
      DEFAULT: '#8992E2',
      light: '#EBEEFC',
    },
    coral: {
      DEFAULT: '#EA4F5F',
      light: '#FF2D42',
    },
    gradientPink: '#F3CBFB',
    gradientBlue: '#85ACBB',
    cyan: {
      DEFAULT: '#02C7E9',
      light: '#A6EBF7',
    },
  },
  esn: {
    secondary: {
      DEFAULT: '#0f4c88',
      light: '#2787D8',
      dark: '#20315b',
      superLight: '#DDEEF7',
    },
    primary: {
      DEFAULT: '#77FFFF',
      light: '#b4ffff',
    },
  },
  eds: {
    pink: {
      DEFAULT: '#EFAAE8',
      light: '#E8D6E9',
    },
    black: {
      DEFAULT: '#121113',
      light: '#2B2A2D',
    },
    red: {
      medium: '#EB5757',
    },
    gray: {
      light: '#403B45',
    },
  },
  els: {
    blue: {
      DEFAULT: '#1D427A',
      grey: '#1D2839',
      light: '#6F99E0',
      lighter: '#DDEEF7',
    },
    gold: {
      light: '#DFBB72',
      dark: '#937334',
    },
    white: {
      warm: '#FFFAF1',
    },
  },
  game: {
    blue: {
      dark: '#004C84',
      light: '#0D75C1',
      midnight: '#1E354C',
    },
    orange: {
      DEFAULT: '#F0A400',
      light: '#F9C971'

    }

  }
};

const ScreenSizeNames = {
  Small: 'sm',
  Medium: 'md',
  Large: 'lg',
  ExtraLarge: 'xl',
  DoubleExtraLarge: '2xl',
  TripleExtraLarge: '3xl',
};

const theme = {
  fontSize: false,
  letterSpacing: false,
  gradientColorStops: false,
  gradientColorStopsCustom: colors,
  ScreenSizeNames,
  screens: {
    [ScreenSizeNames.Small]: '640px',
    [ScreenSizeNames.Medium]: '768px',
    [ScreenSizeNames.Large]: '1024px',
    [ScreenSizeNames.ExtraLarge]: '1280px',
    [ScreenSizeNames.DoubleExtraLarge]: '1536px',
    [ScreenSizeNames.TripleExtraLarge]: '1920px',
  },
  colors,
  fill: colors,
  stroke: colors,
  fontWeight: {
    normal: '400',
    semibold: '600',
    extraBold: '900',
  },
  lineHeight: {
    disable: 0,
    none: 1,
    normal: 'normal',
    snug: 1.375,
  },
  spacing: {
    px: '1px',
    '2px': '2px',
    0: '0rem',
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.375rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    8: '2rem',
    10: '2.5rem',
    12: '3rem',
    14: '3.5rem',
    16: '4rem',
    18: '4.5rem',
    20: '5rem',
    22: '5.5rem',
    24: '6rem',
    28: '7rem',
    30: '7.5rem',
    32: '8rem',
    36: '9rem',
    38: '9.5rem',
    40: '10rem',
    42: '10.5rem',
    44: '11rem',
    56: '14rem',
  },
  borderRadius: {
    none: '0rem',
    0.5: '0.125rem',
    1: '0.25rem',
    2: '0.5rem',
    4: '1rem',
    8: '2rem',
    10: '2.5rem',
    16: '4rem',
    full: '9999rem',
  },
  aspectRatio: [
    '2:1',
    '18:5',
    '16:9',
    '4:3',
    '3:4',
    '1:1',
    '2:3',
    '3:2',
    '5:8',
  ],
  gradientStopValues: {
    ...generateFractionsObject([2, 3, 4, 5, 6, 12]),
    max: '100%',
  },
  extend: {
    boxShadow: {
      quizOption: '4px 4px 0 #000000',
    },
    dropShadow: {
      shifted: '8px 6px 0 #000000',
      small: '2px 2px 0 #000000',
      medium: '0 4px 16px rgba(0, 0, 0, 0.25)',
      big: '8px 8px 0 #000000',
    },
    fontFamily: {
      inter: ['Inter', 'sans-serif'],
      syne: ['Syne', 'sans-serif'],
    },
    width: {
      14: '3.5rem',
      20: '5rem',
      36: '9rem',
      40: '10rem',
      50: '12.5rem',
      52: '13rem',
      60: '15rem',
      64: '16rem',
    },
    height: {
      '2px': '2px',
      10: '2.5rem',
      14: '3.5rem',
      36: '9rem',
      40: '10rem',
      50: '12.5rem',
      52: '13rem',
    },
    margin: {
      '1/2': '50%',
    },
    zIndex: {
      1: 1,
      41: 41,
      60: 60,
      70: 70,
    },
    gridTemplateRows: {
      7: 'repeat(7, minmax(0, 1fr))',
      8: 'repeat(8, minmax(0, 1fr))',
      auto: 'auto',
    },
    gridRow: {
      'span-7': 'span 7 / span 7',
      'span-8': 'span 8 / span 8',
    },
    gridAutoRows: {
      ...generateFractionsObject([2, 3, 4, 5, 6, 7, 8]),
    },
    minHeight: {
      12: '3rem',
      16: '4rem',
      32: '8rem',
      40: '10rem',
    },
    maxHeight: {
      101.5: '25.375rem',
    },
    minWidth: {
      px: '1px',
      16: '4rem',
    },
    keyframes: {
      'zoom-in': {
        '0%': { transform: 'scale(.1)', opacity: 0 },
        '100%': { transform: 'scale(1)', opacity: 1 },
      },
      'title-reveal': {
        '0%': {
          opacity: 0,
          transform: 'translate(0, -.75rem) rotate(-66deg)',
        },
        '40%': {
          opacity: 1,
          transform: 'translate(0,0) rotate(0deg)',
        },
        '60%': {
          opacity: 1,
          transform: 'translate(0,0) rotate(0deg)',
        },
        '80%': {
          transform: 'translate(0,-1rem)',
        },
        '100%': {
          opacity: 1,
          transform: 'translate(0,0)',
        },
      },
      'title-reveal-no-hop': {
        '0%': { opacity: 0, transform: 'translate(0, -.75rem) rotate(-66deg)' },
        '40%': { opacity: 1, transform: 'translate(0,0) rotate(0deg)' },
        '60%': { opacity: 1, transform: 'translate(0,0) rotate(0deg)' },
        '100%': { opacity: 1, transform: 'translate(0,0)' },
      },
      'fade-in': {
        '0%': {
          transform: 'translateY(0.5rem)',
          opacity: 0,
        },
        '100%': {
          opacity: 1,
        },
      },
      'left-bg-element-float': {
        '0%': { transform: 'translate(.5rem, -.5rem)' },
        '50%': { transform: 'translate(.75rem, -.75rem)' },
        '100%': { transform: 'translate(.5rem, -.5rem)' },
      },
      'right-bg-element-float': {
        '0%': { transform: 'translate(-.5rem, -.5rem)' },
        '50%': { transform: 'translate(-.75rem, -.75rem)' },
        '100%': { transform: 'translate(-.5rem, -.5rem)' },
      },
      'right-element-pulse': {
        '80%': { transform: 'rotate(20deg) translate(-2rem, -2rem)' },
        '85%': { transform: 'rotate(20deg) translate(-2rem, -2rem)' },
        '90%': { transform: 'rotate(20deg) translate(0rem, 0rem)' },
        '100%': { transform: 'translate(-.5rem, -.5rem)' },
      },
      'right-shadow-pulse': {
        '40%': { filter: 'hue-rotate(-30deg) brightness(100%)' },
        '80%': { transform: 'rotate(20deg)' },
        '90%': {
          transform: 'rotate(20deg)',
          filter: 'hue-rotate(-30deg) brightness(100%)',
        },
        '100%': { transform: 'rotate(0deg)' },
      },
      'left-element-pulse': {
        '80%': { transform: 'rotate(-20deg) translate(2rem, -2rem)' },
        '85%': { transform: 'rotate(-20deg) translate(2rem, -2rem)' },
        '90%': { transform: 'rotate(-20deg) translate(0rem, 0rem)' },
        '100%': { transform: 'translate(.5rem, -.5rem)' },
      },
      'left-shadow-pulse': {
        '40%': { filter: 'hue-rotate(-30deg) brightness(100%)' },
        '80%': { transform: 'rotate(-20deg)' },
        '90%': {
          transform: 'rotate(-20deg)',
          filter: 'hue-rotate(-30deg) brightness(100%)',
        },
        '100%': { transform: 'rotate(0deg)' },
      },
    },
    animation: {
      'zoom-in': 'zoom-in .6s ease-in-out',
      'title-reveal': 'title-reveal .75s ease-out forwards',
      'title-reveal-no-hop': 'title-reveal-no-hop .75s ease-out forwards',
      'fade-in': 'fade-in .75s ease-in-out forwards',
      'floating-left-element':
        'left-bg-element-float 8s ease-in-out forwards infinite',
      'floating-right-element':
        'right-bg-element-float 5s ease-in-out forwards infinite',
      'pulse-left': 'left-element-pulse 3.25s .75s 1 ease-out',
      'pulse-left-shadow': 'left-shadow-pulse 3.25s .75s 1 ease-out',
      'pulse-right': 'right-element-pulse 3.25s .75s 1 ease-out !important',
      'pulse-right-shadow': 'right-shadow-pulse 3.25s .75s 1 ease-out',
    },
    transitionProperty: {
      zoomIn: 'opacity, transform',
      opacityWithBg: 'opacity, background-color',
      quizOption: 'background-color, transform, box-shadow, border',
    },
  },
};

module.exports = theme;
