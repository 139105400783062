import fetchWithCatch from './fetch-with-catch';

const setFirstPartyCookie = async () => {
  const { data, error } = await fetchWithCatch(() =>
    fetch('/api/first-party-cookie', {
      cache: 'no-store',
    }),
  );

  if (error) {
    console.error('Error setting cookie:', error);

    return undefined;
  }

  return data;
};

export default setFirstPartyCookie;
